@import 'app/catalogue/scss/variable';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

* {
	font-family: $font-family;
	outline: 0;
	margin: 0;
	padding: 0;
	color: $black;
	line-height: 1.5;
	letter-spacing: 0.25px;
	box-sizing: border-box;
}

.fa-download,
.fa-video,
.fa-eye,
.fa-map-location-dot,
.fa-signal-stream,
.fa-copy,
.fa-chart-line {
	background-color: #1158b7;
	color: #fff;
	padding: 8px;
	border-radius: 10px;
}

.text-iudx-primary {
	color: #0b457f;
}

.header-title {
	h3 {
		color: #0b457f;
	}
}
.panels {
	li {
		font-size: 1.5rem;
		padding-left: 12px;
	}
}

.fa-star-of-life {
	font-size: 8px;
	position: relative;
	top: -5px;
	left: 2px;
}

.modal {
	z-index: 1066;
}

.leaflet-popup-close-button {
	display: none;
}

.leaflet-popup-content {
	font-size: 14px !important;
	color: $blue;
}

.cursor-pointer {
	cursor: pointer;
}

.introjs-tooltip.custom-tooltip.introjs-floating {
	width: 40rem;
	max-width: 100rem;
}

.dropdown-btn {
	span {
		font-size: 12px !important;
	}
}

.error-message {
	font-size: 12px;
}

.popup {
	.dropdown-btn {
		height: 6.5rem !important;
		border-radius: 10px !important;
	}
}

.create-request {
	.dropdown-btn {
		padding: 28px 12px 5px 21px !important;

		span {
			font-size: 14px !important;
		}
	}
}

.resource-url-select {
	.dropdown-btn {
		.selected-item {
			a {
				display: none;
			}
		}
	}
}

.apporved-request,
.withdraw-request,
.auditing-header,
.delegates-page,
.provider-request-page,
.provider-policy-page,
.deligate-container,
.provider-policy-page,
.dataset-list-container {
	.multiselect-dropdown {
		width: 50% !important;
	}

	.dropdown-btn {
		padding: 26px 11px 0 19px !important;
		background-color: #fff;

		span {
			font-size: 12px !important;
		}
	}
}

span.iudx-text-danger {
	font-size: 1rem !important;
}

.dataTables_empty {
	background-color: #fff !important;
	padding: 1.6rem !important;
	font-size: 1.4rem !important;
	font-weight: 600 !important;
	color: #042f5b !important;
}

.auditing-header {
	.buttons-pdf {
		display: none !important;
	}
}

.download-audit-page {
	.dt-button.buttons-pdf.buttons-html5 {
		display: flex !important;
		justify-content: flex-end !important;
		padding: 0 !important;
		width: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;

		span {
			width: 100% !important;
			height: auto !important;
			float: left !important;
			transition: 0.5s linear !important;
			position: relative !important;
			display: block !important;
			overflow: hidden !important;
			padding: 9px 15px !important;
			text-align: center !important;
			/* margin: 0 5px !important; */
			background: #0b457f !important;
			color: #fff !important;
			text-transform: capitalize !important;
			font-weight: 500 !important;
			/* border-radius: 5px !important; */
			font-size: 14px !important;

			&:before {
				position: absolute !important;
				content: '' !important;
				left: 0 !important;
				bottom: 0 !important;
				height: 4px !important;
				width: 100% !important;
				border-bottom: 4px solid transparent !important;
				border-left: 4px solid transparent !important;
				box-sizing: border-box !important;
				transform: translateX(100%) !important;
			}

			&:after {
				position: absolute !important;
				content: '' !important;
				top: 0 !important;
				left: 0 !important;
				width: 100% !important;
				height: 4px !important;
				border-top: 4px solid transparent !important;
				border-right: 4px solid transparent !important;
				box-sizing: border-box !important;
				transform: translateX(-100%) !important;
			}

			&:hover {
				box-shadow: 0 5px 15px rgba(255, 255, 255, 0.5) !important;
				background-color: #fff !important;
				color: #0b457f !important;
				transition: all 0.4s ease !important;
			}

			&:hover:before {
				border-color: #0b457f !important;
				height: 100% !important;
				transform: translateX(0) !important;
				transition: 0.3s transform linear, 0.3s height linear 0.3s !important;
			}

			&:hover:after {
				border-color: #0b457f !important;
				height: 100% !important;
				transform: translateX(0) !important;
				transition: 0.3s transform linear, 0.3s height linear 0.5s !important;
			}
		}
	}
}

.dropdown-multiselect__caret {
	line-height: 40px !important;
}

// .tooltip-inner {
//   font-size: 12px !important;
//   text-align: left !important;
//   padding: 1rem !important;
//   width: 100% !important;
//   max-width: 250px !important;
//   background-color: #e9ecef !important;
//   color: black !important;
//   border-radius: 5px !important;
// }

// .tooltip .tooltip-arrow {
//   display: none !important;
// }

table.dataTable.no-footer {
	border-bottom: #dee2e6 !important;
}

.custom-tooltip {
	* {
		color: #4a4a4a;
		font-size: 18px;
	}

	.introjs-tooltip-title {
		color: $blue;
	}

	.introjs-tooltiptext {
		padding: 10px 15px;
		text-align: center;
	}

	.introjs-tooltip-header {
		padding-left: 20px;
		padding-right: 10px;
		padding-top: 5px;
	}

	.introjs-skipbutton {
		padding: 3px 0;
	}

	.introjs-tooltipbuttons {
		.introjs-button {
			width: 18%;
			height: auto;
			transition: 0.5s linear;
			position: relative;
			display: block;
			overflow: hidden;
			padding: 9px 0px;
			text-align: center;
			margin: 0 5px;
			background: #0b457f;
			color: #fff;
			text-transform: capitalize;
			font-weight: 500;
			border-radius: 5px;
			font-size: 14px;
		}

		.introjs-button:before {
			position: absolute;
			content: '';
			left: 0;
			bottom: 0;
			height: 4px;
			width: 100%;
			border-bottom: 4px solid transparent;
			border-left: 4px solid transparent;
			box-sizing: border-box;
			transform: translateX(100%);
		}

		.introjs-button:after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 4px;
			border-top: 4px solid transparent;
			border-right: 4px solid transparent;
			box-sizing: border-box;
			transform: translateX(-100%);
		}

		.introjs-button:hover {
			box-shadow: 0 5px 15px rgba(255, 255, 255, 0.5);
			background-color: #fff;
			color: #0b457f;
			transition: all 0.4s ease;
			cursor: pointer;
		}

		.introjs-button:hover:before {
			border-color: #0b457f;
			height: 100%;
			transform: translateX(0);
			transition: 0.3s transform linear, 0.3s height linear 0.3s;
		}

		.introjs-button:hover:after {
			border-color: #0b457f;
			height: 100%;
			transform: translateX(0);
			transition: 0.3s transform linear, 0.3s height linear 0.5s;
		}

		.introjs-button.introjs-disabled {
			background-color: #92c2f2;
			pointer-events: none;
			opacity: 0.5;
			cursor: not-allowed;
		}

		.introjs-button.introjs-disabled:hover,
		.introjs-button.introjs-disabled:hover:before,
		.introjs-button.introjs-disabled:hover:after {
			box-shadow: none;
			background-color: #0b457f;
			color: #fff;
			cursor: not-allowed;
		}

		.introjs-button.introjs-disabled:before,
		.introjs-button.introjs-disabled:after {
			display: none;
		}
	}
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgb(0 0 0 / 60%);
}

html {
	font-size: 62.5%;
}

html,
body {
	background-color: $white;
}

body {
	font-size: 1.6rem !important;
}

a,
button {
	cursor: pointer;
}

a {
	text-decoration: none !important;
}

ul {
	list-style-type: none;
}

.img-icon {
	width: 25px !important;
}

.text-iudx-primary {
	color: #042f5b;
}

.btn {
	box-sizing: border-box;
	appearance: none;
	background-color: transparent;
	border: 2px solid red;
	border-radius: 0.6em;
	color: red;
	cursor: pointer;
	display: flex;
	align-self: center;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1;
	margin: 20px;
	padding: 0.7em 1em !important;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;

	&:hover,
	&:focus {
		color: #fff;
		outline: 0;
	}
}

.btn.third {
	border-color: #0b457f;
	color: #fff;
	box-shadow: 0 0 40px 40px #0b457f inset, 0 0 0 0 #0b457f;
	transition: all 150ms ease-in-out;

	&:hover {
		box-shadow: 0 0 10px 0 #0b457f inset, 0 0 10px 4px #0b457f;
	}
}

span.text-danger {
	font-size: 11px;
}

ngb-pagination ul > li {
	margin: 0 1rem !important;

	a {
		background: transparent !important;
		display: flex !important;
		align-items: center !important;
		font-size: 1.5rem !important;
		cursor: pointer !important;
		border: 1px solid #0b457f !important;
		border-radius: 50% !important;
		width: 3rem !important;

		span {
			font-weight: 600 !important;
			margin: 0 auto !important;
			color: #0b457f;
		}
	}

	div {
		color: #2c2c2c !important;
		font-size: 12px !important;
		vertical-align: top !important;
		font-weight: 500 !important;
	}
}

.section-title {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;

	h2,
	h2 span {
		font-weight: 600;
		margin: 0 25px 0 0;
		font-size: 32px;
		line-height: 1;

		span {
			span {
				text-transform: capitalize;
			}
		}
	}

	.view-all {
		color: $blue;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin: 0 0 0 auto;

		img {
			margin: 0 0 0 5px;
		}
	}
}

ngb-pagination ul > li.disabled > a {
	opacity: 75% !important;
	cursor: not-allowed !important;
	color: #2c2c2c !important;
	border-color: #b2b3bc !important;
	border-radius: 50% !important;

	span {
		color: #2c2c2c;
	}
}

button {
	outline: none;
	border: none;
	box-sizing: border-box;

	&.btn {
		background: $blue;
		color: $white;
		padding: 20px 65px;
		border: 1px solid $blue;
		border-radius: 5px;
	}

	&.ghost {
		border: 1px solid $white;
		color: $white;
		background: transparent;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* DATASET CARD */
app-dataset-card {
	width: calc(25% - 30px);
}

@media (max-width: 1100px) {
	app-dataset-card {
		width: calc(33.33% - 30px);
		max-width: calc(33.33% - 30px);
	}
}

@media (max-width: 900px) {
	app-dataset-card {
		width: calc(50% - 30px);
		max-width: calc(50% - 30px);
	}
}

@include media-breakpoint-down(sm) {
	app-dataset-card {
		width: 100%;
		max-width: unset;
		margin: 0 0 15px;
	}
}

.popup-custom .leaflet-popup-tip,
.popup-custom .leaflet-popup-content-wrapper {
	border-radius: 5px;

	img {
		width: 25px;
		height: 25px;
		display: block;
		margin: 0 10px 0 0;
	}

	span {
		font-size: 14px;
		display: block;
	}

	.with-image {
		display: flex;
		align-items: flex-start;
		margin: 0 0 5px;

		span {
			font-weight: 600;
		}
	}

	.resource {
		text-align: center;
		color: var(--grey);
		font-weight: 600;

		b {
			font-size: 14px;
		}

		&.dataset {
			b {
				color: var(--grey);
			}
		}
	}

	.anchor {
		font-weight: 700;
		color: var(--blue);
		cursor: pointer;
	}
}

.leaflet-bottom {
	display: none;
}

.multiselect-item-checkbox {
	input[type='checkbox'] + div:before {
		border-radius: 0% !important;
	}
}

.provider-container {
	.multiselect-dropdown {
		width: 50% !important;
	}

	.dropdown-btn {
		padding: 26px 11px 0 19px !important;
		background: #fff;
	}
}

.product-selected {
	.multiselect-dropdown .dropdown-btn {
		background-color: $white;
	}
}
.rating-div {
	.tooltip-inner {
		min-width: 150px !important;
	}
}

.provider-multi-select,
.rs-admin-single-select,
.provider-list-single-select,
.consumer-datasets-single-select,
.consumer-approved-single-select,
.resource-url-select,
.radio-btn-single-select {
	.multiselect-item-checkbox {
		input[type='checkbox'] + div:before {
			border-radius: 50% !important;
			left: 0px !important;
			width: 16px !important;
			height: 16px !important;
			margin-top: -10px !important;
		}
	}
}

.tooltip-inner {
	font-size: 12px !important;
	text-align: left !important;
	padding: 1rem !important;
	width: 100% !important;
	min-width: 250px !important;
	background-color: #f2f2f2 !important;
	color: #000000 !important;
	border-radius: 5px !important;
	z-index: 100001;
	text-align: center !important;
}

.tooltip .tooltip-arrow {
	display: none !important;
}

.btn {
	background-color: $blue !important;
	margin: 1rem;
	font-weight: 500;
	font-size: 12px !important;
	color: #fff !important;
	transition: 0.4s;
	padding: 0.5rem 1.8rem;
	border: none;
	border-radius: 5px !important;

	&:hover {
		opacity: 70%;
		background-color: #0b457f;
	}
}

.bottom {
	font-size: 1.3rem;
	margin: 2rem 0 3rem;

	.form-select {
		background-color: white;
		margin: 0 0.5rem;
	}

	/* stylelint-disable */
	.dataTables_paginate {
		span {
			.paginate_button {
				background-color: white !important;

				&.current {
					background-color: #042f5b !important;
					color: white !important;

					&:hover {
						color: white !important;
					}
				}

				&:hover {
					background: #042f5b !important;
					opacity: 80%;
				}
			}
		}

		a {
			&.paginate_button {
				&.disabled {
					color: $grey !important;
					display: none;

					&:hover {
						background: white !important;
						// opacity: 80%;
					}
				}

				&:hover {
					background: #042f5b !important;
					opacity: 80%;
				}
			}
		}
	}
}

.top {
	font-size: 1.3rem;

	label {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		padding-top: 0;

		select {
			margin: 1rem;
		}

		input {
			width: 88% !important;
			padding: 5px;
			font-size: 1.2rem;
			margin-left: 1rem;
			border: none;
			border-bottom: 1px solid $border-color;

			&:focus {
				outline: none !important;
			}
		}

		&::after {
			// TODO: Used for datatables search icon. Currently it was not placed at the position. Need to fix
			// content: url("/assets/search.svg");
			content: none;
		}
	}
}

//multiselect drop down styling
.selected-item-container .selected-item span {
	color: white !important;
}

.consumer-datasets-multiselect {
	.selected-item {
		a {
			display: none;
		}
	}
}

input[type='search'] {
	background-color: #fff !important;
}

.dataTables_wrapper .dataTables_length select {
	background-color: #fff !important;
}

//New Button design
.box {
	width: 35%;
	height: auto;
	float: left;
	transition: 0.5s linear;
	position: relative;
	display: block;
	overflow: hidden;
	padding: 9px 0px;
	text-align: center;
	margin: 0 5px;
	// background: #0b457f; 
	background: $logo-blue;  // My code
	color: #fff;
	text-transform: capitalize;
	font-weight: 500;
	border-radius: 5px;
	font-size: 14px;
}

.box:before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	height: 4px;
	width: 100%;
	border-bottom: 4px solid transparent;
	border-left: 4px solid transparent;
	box-sizing: border-box;
	transform: translateX(100%);
}

.box:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
	border-top: 4px solid transparent;
	border-right: 4px solid transparent;
	box-sizing: border-box;
	transform: translateX(-100%);
}

.box:hover {
	box-shadow: 0 5px 15px rgba(255, 255, 255, 0.5);
	background-color: #fff;
	// color: #0b457f;
	color: $logo-blue;  // My code
	transition: all 0.4s ease;
	cursor: pointer;
}

.box:hover:before {
	// border-color: #0b457f;
	border-color: $logo-blue;  // My code
	height: 100%;
	transform: translateX(0);
	transition: 0.3s transform linear, 0.3s height linear 0.3s;
}

.box:hover:after {
	// border-color: #0b457f;
	border-color: $logo-blue; // My code
	height: 100%;
	transform: translateX(0);
	transition: 0.3s transform linear, 0.3s height linear 0.5s;
}

button {
	color: black;
	text-decoration: none;
	cursor: pointer;
	outline: none;
	border: none;
	background: transparent;
}

.dropdown-btn {
	background-color: white;
	padding: 23px 12px 0 21px !important;
}

.dropdown-list {
	margin: 0 !important;
	transition: all 0.5s ease-in-out !important;
}

.iudx-warning {
	background-color: #ffd700 !important;
}

.iudx-success {
	background-color: #0a9 !important;
}

.iudx-danger {
	background-color: rgb(184, 27, 27) !important;
}

.iudx-text-danger {
	color: rgb(184, 27, 27) !important;
}

//for map in instances list page catalogue
.city-map-tooltip {
	font-size: 16px;
}

//instances page layers control hidden
.leaflet-touch .leaflet-control-layers {
	display: none !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '~leaflet/dist/leaflet.css';
@import '~leaflet-draw/dist/leaflet.draw.css';
