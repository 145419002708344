@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins/breakpoints";

//  Colors
$white: #fff;
$black: #2c2c2c;
$grey: #6c6c6c;
$dark-grey: #6f8088;
$light-grey: #b2b3bc;
$red: #ff6b6b;
$orange: #c77d00;
$green: #17c3b2;
$light-green: #009e5f;
$toaster-green: #1d8006;
$toaster-red: #bd0505;
$blue: #0b457f;
$icon-blue: #0f6da5;
$light-blue: #0aabb4;
$light2-blue: #0039a6;
$dark-blue: #042f5b;
$cyan: #208496;
$off-white: #fcfcfc;
$background: #f2f2f2;
// $ribbon-background: #b8d8d8; // My code
$ribbon-background: #F1F1E6 ; // My code
$logo-blue: #1B3C73; // My Code
$dark-green: #4f6367; // My code
$light-pink: #FFF7F2; // My code
$light-background: #f8f8f8;
$light-brown: #BDA79E; // My code
$border: #dadada;
$border-light: #efecec;
$light-bluish: #007697;
$disabled: #d3d3d3;
$gold: #ffd700;
$disabled-inpt-background: #d9d9d9;
$input-white-background: #ccc;
$input-box-shadow: #007bff;
$adex-theme: #0a9;
$high-light-row: #caddf0;

//  Styles
$font-family: "Epilogue", sans-serif;
$font-normal: 400;
$font-bold: 500;
$font-extra-bold: 600;
$font-size: 1.6rem;

// Spacing
$m-xsm: 10px;
$m-sm: 15px;
$m-md: 25px;
$m-lg: 30px;
$m-xl: 40px;
$p-xsm: 10px;
$p-sm: 15px;
$p-md: 20px;
$p-lg: 30px;
$p-xl: 40px;
